import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./styles.css";
import BGVideo from "./Assets/BG Video.mp4";
import { Link, animateScroll as scroll } from "react-scroll";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import Cards from "./cards";
import CardsTB from "./cardsTB";
import bg2 from "./Assets/Arcs.jpg";
import bg1 from "./Assets/Locations.jpg";

class App extends Component {
  render() {
    return (
      <div>
        <div id="Up"></div>
        <div className="Sidebar">
          <Sidebar />
        </div>
        <Navbar />
        <div className="FirstBlock">
          <video className="BGVideo" autoPlay loop muted>
            <source src={BGVideo} type="video/mp4" />
          </video>
          <p style={{ margin: 0, marginBottom: -10 }}>PILLOW</p>
          <p style={{ fontSize: 35 }}>
            Consider <span style={{ color: "yellow" }}>IT</span> Done
          </p>
          <Link
            to="ourValues"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className="Button">
              Get Started
              <i class="fa-solid fa-angle-right fa-xs" id="icon"></i>
            </button>
          </Link>
        </div>

        <div id="ourValues">
          <div class="titleBox">
            <h1>Our Values</h1>
          </div>

          <div class="SecondBlock">
            <div className="CardsWrapper">
              <Cards
                bgColor={"#034777"}
                icon={
                  <i
                    style={{ fontSize: 80, marginBottom: 20 }}
                    class="fas fa-handshake"
                  ></i>
                }
                header={"WE DO CARE"}
                text={
                  "We genuinely care about our clients and the difference we make to the people who receive our service. Caring is what drives the company and all our staff forward ensuring we continue to deliver superior & customised services. We recognise, value and care about the communities we serve. We work on our projects with enthusiasm while paying attention to all the details with thoroughness, consistency and accountability. So, let’s care about each other’s just like trustful partners!"
                }
              />
              <Cards
                bgColor={"#046BB3"}
                icon={
                  <i
                    style={{ fontSize: 80, marginBottom: 20 }}
                    class="fa-solid fa-lightbulb"
                  ></i>
                }
                header={"WE ARE INNOVATION DRIVEN"}
                text={
                  "We are an IT company able to provide a wide spectrum of services meeting very different needs.  Complex & difficult situations are stimulating challenges for us. We do operate with agility, with the right level of disruptiveness, the one that optimises the transformation-results ratio.Our consultants and Data Scientists support you in the identification and realisation of Proof Of Concept/ Proof Of Value, in the industrialisation of solutions, as well as in the phases of strategic reflection and construction of realistic roadmaps"
                }
              />
              <Cards
                bgColor={"#059BCA"}
                icon={
                  <i
                    style={{ fontSize: 80, marginBottom: 20 }}
                    class="fa-solid fa-award"
                  ></i>
                }
                header={"WE VALUE AUTHENTICITY"}
                text={
                  "Our Authenticity and steadfast belief in what we do is a value we cannot function without. Authenticity empowers people to be who they are, and we want to help you be the best version of yourself, providing the best possible solutions to your customers. We do believe that building trust builds sustainable business which is creating a virtuous circle of success"
                }
              />
              <Cards
                bgColor={"#4799B3"}
                icon={
                  <i
                    style={{ fontSize: 80, marginBottom: 20 }}
                    class="fa-solid fa-clock"
                  ></i>
                }
                header={"... AND ARE PRAGMATIC!"}
                text={
                  "We are pragmatic because we focus on our clients’ real needs & always choose simple and effective solutions.  We work proactively, in an incremental, iterative model. We make sure that we reduce the risk of failure, get quick feedback, and react rapidly to changes"
                }
              />
            </div>
          </div>
        </div>

        <div id="ourExpertise">
          <div class="titleBox">
            <h1>Our Expertise</h1>
          </div>

          <div
            style={{
              backgroundImage: `url(${bg2})`,
            }}
            className="ThirdBlock"
          >
            <div className="CardsWrapperTB">
              <CardsTB
                header={"IT Solutions"}
                text={
                  "Digitalise business processes and design solutions for complex problems through innovative applications. Promote mobile applications in our solutions."
                }
              />
              <CardsTB
                header={"Consulting"}
                elem={
                  <>
                    Offer a consulting service in the following fields: <br />
                    - Cyber security, Pentesting and Audit <br />
                    - Information Security, IT Governance and Risk management
                    <br />- Cryptography and electronic signature
                  </>
                }
              />
              <CardsTB
                header={"R&D and Innovation"}
                text={
                  "Develop proof of concept or proof of value solutions using innovative technologies such as: Blockchain, Artificial Intelligence, Internet of things, Cryptography and Electronic Signature."
                }
              />
            </div>
          </div>
        </div>

        <div id="contact">
          <div class="titleBox">
            <h1>Contact</h1>
          </div>

          <div className="FourthBlock">
            <div className="Contacts">
              <h1 style={{ marginBottom: 40 }}>Contact Info</h1>
              <span>
                <i class="fa-solid fa-location-dot"></i> Addresses:
              </span>
              Headquarter: 16 el imam malek street, La Marsa Tunis, Tunisia
              <span>Commercial Offices: Nice, France / Montreal, Canada</span>
              <span>
                <i class="fa-solid fa-at"></i> Email:
              </span>
              Contact@pillowsolutions.io
            </div>
            <img className="ContactImg" src={bg1} alt="Pillow-Locations" />
          </div>
        </div>

        <div className="Footer">
          <Link to="Up" spy={true} smooth={true} offset={-70} duration={500}>
            <i class="fa-solid fa-arrow-up"></i>
          </Link>
          <div className="FooterColumn">
            <p>About Us</p>
            <p>Who we are ?</p>
            <p>Sponsors</p>
            <p>Terms of Service</p>
          </div>
          <div className="FooterColumn">
            <p>Contact Us</p>
            <p>Contact</p>
            <p>Support</p>
          </div>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
