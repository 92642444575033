import React from "react";

const Cards = (props) => {
  return (
    <div className="Card" style={{ backgroundColor: props.bgColor }}>
      {props.icon}
      <h3 style={{ textAlign: "center" }}>{props.header}</h3>
      <div className="CardText">{props.text}</div>
    </div>
  );
};

export default Cards;
