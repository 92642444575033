import React from "react";

const CardsTB = (props) => {
  return (
    <div className="CardTB">
      <h3 style={{ marginBottom: 25, marginTop: -25 }}>{props.header}</h3>
      <div className="CardText">{props.text}</div>
      <div className="CardText">{props.elem}</div>
    </div>
  );
};

export default CardsTB;
