import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./styles.css";
import { Link, animateScroll as scroll } from "react-scroll";
import logo from "./Assets/pillow.png";

export default function Navbar() {
  return (
    <>
      <div className="Navbar">
        <div className="NavLogo">
          <Link to="Up" spy={true} smooth={true} offset={-70} duration={500}>
            <img src={logo} alt="pillowLogo" width={200} height={50} />
          </Link>
        </div>
        <div className="NavbarRightContainer">
          <Link
            className="NavbarElement"
            activeClass="NavbarElementActive"
            to="ourValues"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <span>Our Values</span>
          </Link>
          <Link
            className="NavbarElement"
            activeClass="NavbarElementActive"
            to="ourExpertise"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <span>Our Expertise</span>
          </Link>
          <Link
            className="NavbarElement"
            activeClass="NavbarElementActive"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <span>Contact</span>
          </Link>
        </div>
      </div>
    </>
  );
}
