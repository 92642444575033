import React, { useState } from "react";
import logo from "./Assets/pillow.png";
import { Link, animateScroll as scroll } from "react-scroll";

const Sidebar = () => {
  const [sideView, setSideView] = useState(true);
  return (
    <>
      {sideView ? (
        <div className="collapsedSideBar">
          <img
            style={{ cursor: "pointer" }}
            src={logo}
            alt="pillowLogo"
            width={200}
            height={50}
          />
          <i
            onClick={() => {
              setSideView(!sideView);
            }}
            style={{ fontSize: 25, cursor: "pointer" }}
            class="fa-solid fa-bars"
          ></i>
        </div>
      ) : (
        <div className="SidebarContainer">
          <div className="collapsedSideBar">
            <img
              style={{ cursor: "pointer" }}
              src={logo}
              alt="pillowLogo"
              width={200}
              height={50}
            />
            <i
              onClick={() => {
                setSideView(!sideView);
              }}
              style={{ fontSize: 25, cursor: "pointer" }}
              class="fa-solid fa-bars"
            ></i>
          </div>
          <Link
            className="NavbarElement"
            activeClass="NavbarElementActive"
            to="ourValues"
            spy={true}
            smooth={true}
            offset={-279}
            duration={500}
            onClick={() => {
              setSideView(!sideView);
            }}
          >
            <span>Our Values</span>
          </Link>
          <Link
            onClick={() => {
              setSideView(!sideView);
            }}
            className="NavbarElement"
            activeClass="NavbarElementActive"
            to="ourExpertise"
            spy={true}
            smooth={true}
            offset={-279}
            duration={500}
          >
            <span>Our Expertise</span>
          </Link>
          <Link
            onClick={() => {
              setSideView(!sideView);
            }}
            className="NavbarElement"
            activeClass="NavbarElementActive"
            to="contact"
            spy={true}
            smooth={true}
            offset={-279}
            duration={500}
          >
            <span>Contact</span>
          </Link>
        </div>
      )}
    </>
  );
};

export default Sidebar;
